//https://coolors.co/264653-2a9d8f-e9c46a-f4a261-e76f51
//Color palette
$dark-green:#264653;
$primary-green:#2a9d8f;
$dark-organge:#e76f51;
$primary-organge:#f4a261;
$dark-pink:#ac0079;

//font
$font-family : '';
$font-bold: 700;
$font-extra-bold:800
