@import '../../Theme/variables.scss';

.booking-final-form{
    margin-top: 4rem;
}
.submit-btn-container{
    margin-top :2rem;
    text-align: center;
}
.primary-select{
    margin-bottom: 1em;
    padding-bottom: 10px;
}